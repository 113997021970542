// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------
const PRODUCT_DEFAULT_TEXT = `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum`;
const companyName = `Южная Северная Америка Экспорт и инвестиции`;
const ru = {
  // ------------------------------- HEADER LINKS --------------------------------------------
  home: 'дом',
  about: 'О нас',
  contactUs: 'Свяжитесь с нами',
  services: 'Услуги',
  // ------------------------------- FOOTER --------------------------------------------
  shortDesc: `${companyName} первоначально разветвлялась от материнских компаний, которые были основаны в Техасе, США в 1987 году и основаны в Техасе в 1992 году. Обе корпорации имеют опыт во всех видах распределения продуктов питания.`,
  sitemap: 'карта сайта',
  product: 'Товары',
  service: 'Услуги',
  copyWrite: '© 2022. Все права защищены',
  email: 'Эл. адрес',
  // ------------------------------- OTHERS --------------------------------------------
  submit: 'Отправить сейчас',
  weCanHelp: 'Мы можем помочь',
  banksConnection: 'Соединение с банками',

  //! ------------------------------- HOME PAGE --------------------------------------------
  // ------------------------------- Hero --------------------------------------------
  hero: {
    title: companyName,
    // subTitle: 'A future of collaborating and creating the only way we know how. together',
    subTitle: `Мы по всему миру!!`,
  },
  // ------------------------------- Our Products --------------------------------------------
  products: {
    title: `Широкий ассортимент товаров и услуг`,
    subTitle: `Мы зарекомендовали себя как надежный поставщик в регионе. Уделяя особое внимание качеству и срокам, мы постоянно стремимся предоставлять лучшие продукты и услуги и стремимся к установлению прочных, долгосрочных и взаимовыгодных отношений с нашими клиентами и заказчиками. Качество продукции и безупречный сервис являются основными обязательствами нашей компании, укрепляющими ее репутацию и создающими правильную среду для дальнейшего роста и прибыльности. Наши основные продукты питания, такие как бразильский сахар, соевые бобы, рис, растительное масло, пшеница, кукуруза, мука, кофе, кардамон и т. д.`,
    grains: { title: 'Зерновые', desc: PRODUCT_DEFAULT_TEXT },
    brazilian_sugar: { title: 'Бразильский сахар', desc: PRODUCT_DEFAULT_TEXT },
    livestock: { title: 'Животноводство и молочные продукты', desc: PRODUCT_DEFAULT_TEXT },
    vegetables: { title: 'Овощи и фрукты', desc: PRODUCT_DEFAULT_TEXT },
    coffee: { title: 'Кофе, шоколад и кардамон', desc: PRODUCT_DEFAULT_TEXT },
    mining: { title: 'Добыча', desc: PRODUCT_DEFAULT_TEXT },
    petroleum: { title: 'Нефть и нефтехимия', desc: PRODUCT_DEFAULT_TEXT },
    oil: { title: 'Масло растительного происхождения', desc: PRODUCT_DEFAULT_TEXT },
    finance: {
      title: 'Финансы и инвестиции',
      cards: {
        0: {
          title: `Вы столкнулись с финансовыми проблемами? \n Вам трудно финансировать свой проект?`,
          focusText: `от 100 000 до 25 000 000 000 долларов`,
          supportingText: `готов для вас проект с 0% годовых в течение 72 часов\n 0% годовых до 10 лет`,
        },
        1: {
          title: `Вам нужны деньги вперед для вашего инструмента?! \n Вам нужно монетизировать свой инструмент?!!`,
          focusText: `Sblc, dlc, lc, bg, mtn, ltn и государственная гарантия`,
          supportingText: ``,
        },
        2: {
          title: `У вас есть шкатулки с сокровищами?`,
          focusText: `1924 немецкий \n дракон \n зимбабве \n ираки динар`,
          supportingText: ``,
        },
      },
    },
    logistics: { title: 'Логистика и транспорт', desc: PRODUCT_DEFAULT_TEXT },
    certificates: 'Сертификаты',
  },
  // ------------------------------- Our Strength --------------------------------------------
  strength: {
    title: `Наша сила`,
    subTitle: `Наши корпоративные сильные стороны заключаются в стратегических и органичных отношениях, позволяющих в полной мере использовать преимущества Sumitomo.
    Активы, ресурсы и сеть группы корпораций. Мы стремимся быть лучшим партнером, обеспечивая проактивное
    предложения и всесторонняя поддержка во всех аспектах бизнеса по поставкам металлопродукции и инвестированию
    проекта, как благодаря комплексной корпоративной силе, так и нашему опыту`,
    items: {
      0: `Различные достижения/сертификаты`,
      1: `5 банков мирового уровня`,
      2: `Современные блоки обработки`,
      3: `40 000+ тонн торговли ежегодно`,
      4: `Групповой оборот более 10 миллионов`,
      5: `500+ уважаемых клиентов`,
    },
    secondaryText: `${companyName} сотрудничает с банками мирового класса для обеспечения международных и облегчения финансовых транзакций, некоторые из которых: Citi, WElls Fargo, JPMorgan, BBVA, Santander, HSBC, Barclays, UBS, Credit Suisse, DUTCHE BANK, UOB, DBS`,
  },
  // ------------------------------- How we Stand Out --------------------------------------------
  standOut: {
    title: `Что отличает нас !!`,
    subTitle: `Чем мы отличаемся от остальных..`,
    items: {
      0: `Прямая отгрузка покупателю как навалом, так и тарным грузом`,
      1: `Эффективное управление экспортным предложением, начиная от загрузки контейнера и заканчивая чартерным кредитом`,
      2: `Предоставление кредита на срок от 60 до 180 дней по международным кредитным нормам`,
      3: `Высоко конкурентоспособная цена для прямых трейдеров-пользователей и продажа трейдером`,
      4: `Большие складские помещения на заводах и в портах`,
    },
  },
  // ------------------------------- About us (We are allover the world) --------------------------------------------
  allOverTheWorld: {
    title: 'О нас',
    subTitle: `Наши офисы по всему миру находятся в: Бразилия (главный филиал) - Колумбия - Мексика - Чили - Аргентина - Панама - Коста-Рика - США - Великобритания - Швейцария - Турция - Сингапур - Гонконг - Китай - Канада (скоро открытие) - США Арабские Эмираты (скоро открытие)`,
  },
  learn_more: 'Учить больше',

  //! ----------------------------------------------------------------------------
  // ------------------------------ CONTACT US PAGE ----------------------------------
  contactUsPage: {
    title: 'Свяжитесь с нами',
    subTitle: `Дружелюбная команда поддержки клиентов, которая всегда на расстоянии одного клика`,
    formTitle: `Не стесняйтесь обращаться к нам, мы будем рады услышать от вас`,
    formLabels: {
      name: 'имя',
      email: 'Эл. адрес',
      subject: 'предмет',
      message: 'введите ваше сообщение здесь',
    },
    locations: {
      // 0: `Dubai%Shaikh Zayed Road, H Tower, 2501%(971) 1234-567`,
      0: `Бразилия`,
      1: `Колумбия`,
      2: `Швейцария`,
      3: `Турция`,
    },
  },
};

export default ru;
