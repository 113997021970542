// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------
const PRODUCT_DEFAULT_TEXT = `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum`;
const companyName = `南北美洲出口与投资`;
const ca = {
  // ------------------------------- HEADER LINKS --------------------------------------------
  home: '家',
  about: '关于',
  contactUs: '联系我们',
  services: '服务',
  // ------------------------------- FOOTER --------------------------------------------
  shortDesc: `${companyName} 最初由母公司于 1987 年在美国德克萨斯州成立，并于 1992 年在德克萨斯州成立。两家公司都在各种食品分销方面拥有丰富的经验`,
  sitemap: '网站地图',
  product: '产品',
  service: '服务',
  copyWrite: '© 2022. 版权所有',
  email: '电子邮件',
  // ------------------------------- OTHER --------------------------------------------
  submit: '现在提交',
  banksConnection: '银行连接',
  weCanHelp: '我们可以提供帮助',
  //! ------------------------------- HOME PAGE --------------------------------------------
  // ------------------------------- Hero --------------------------------------------
  hero: {
    title: companyName,
    // subTitle: 'A future of collaborating and creating the only way we know how. together',
    subTitle: `我们遍布世界各地！！`,
  },
  // ------------------------------- Our Products --------------------------------------------
  products: {
    title: `广泛的产品和服务`,
    subTitle: `我们已成为该地区可靠的供应商。我们特别强调质量和进度，不断努力提供最好的产品和服务，并致力于与我们的客户和客户建立牢固、持久和互惠互利的关系。产品的质量和完善的服务是我们公司的主要承诺，提高声誉，为进一步的增长和盈利创造合适的环境。我们的主要食品有巴西糖、大豆、大米、食用油、小麦、玉米、面粉、咖啡、豆蔻等。`,
    grains: { title: '谷物', desc: PRODUCT_DEFAULT_TEXT },
    brazilian_sugar: { title: '巴西糖', desc: PRODUCT_DEFAULT_TEXT },
    livestock: { title: '畜牧和乳制品', desc: PRODUCT_DEFAULT_TEXT },
    vegetables: { title: '蔬菜水果', desc: PRODUCT_DEFAULT_TEXT },
    coffee: { title: '咖啡、巧克力和豆蔻', desc: PRODUCT_DEFAULT_TEXT },
    mining: { title: '矿业', desc: PRODUCT_DEFAULT_TEXT },
    petroleum: { title: '石油石化l', desc: PRODUCT_DEFAULT_TEXT },
    oil: { title: '植物源油', desc: PRODUCT_DEFAULT_TEXT },
    finance: {
      title: '金融与投资',
      cards: {
        0: {
          title: `您是否面临财务问题？ \n 您是否在为您的项目融资而苦苦挣扎？`,
          focusText: `100,000 美元至 25,000,000,000 美元`,
          supportingText: `为您准备好在 72 小时内获得 0% 利息的项目 \n 长达 10 年的 0% 利息`,
        },
        1: {
          title: `您需要为您的乐器预付现金吗？！ \n 您需要通过您的乐器获利吗？！！`,
          focusText: `Sblc、dlc、lc、bg、mtn、ltn 和主权担保`,
          supportingText: ``,
        },
        2: {
          title: `你有金库吗？`,
          focusText: `1924 年德语 \n 龙 \n 津巴布韦 \n 伊拉克第纳尔 `,
          supportingText: ``,
        },
      },
    },
    logistics: { title: '物流运输', desc: PRODUCT_DEFAULT_TEXT },
    certificates: '证书',
  },
  // ------------------------------- Our Strength --------------------------------------------
  strength: {
    title: `我们的实力`,
    subTitle: `我们的企业优势在于战略和有机关系，以充分发挥住友商事集团的资产、资源和网络优势。我们的目标是成为最佳合作伙伴，同时通过综合企业实力和我们的专业知识，为金属产品供应和投资项目的业务各个方面提供积极的建议和全面的支持`,
    items: {
      0: `各种成就/证书`,
      1: `5家世界级银行`,
      2: `最先进的处理单元`,
      3: `年交易量 40,000+ 吨`,
      4: `10+ 百万团体营业额`,
      5: `500+ 尊贵客户`,
    },
    secondaryText: `${companyName} 与世界级银行合作，以确保国际和促进金融交易，其中一些是：花旗银行、富国银行、摩根大通、BBVA、桑坦德银行、汇丰银行、巴克莱银行、瑞银、瑞士信贷、荷兰银行、大华银行、星展银行`,
  },
  // ------------------------------- How we Stand Out --------------------------------------------
  standOut: {
    title: `有什么区别我们！`,
    subTitle: `我们如何脱颖而出..`,
    items: {
      0: `散装和袋装货物直接运送给买方`,
      1: `有效处理从集装箱装载到租船信用的出口报价`,
      2: `根据国际信贷规范安排 60 至 180 天的信贷安排`,
      3: `极具竞争力的价格直接用户交易者和交易者销售`,
      4: `工厂和港口的大型仓储设施`,
    },
  },
  // ------------------------------- About us (We are allover the world) --------------------------------------------
  allOverTheWorld: {
    title: '关于我们',
    subTitle: `我们在世界各地的办事处位于：巴西（主要分支机构）-哥伦比亚-墨西哥-智利-阿根廷-巴拿马-哥斯达黎加-美国-英国-瑞士-土耳其-新加坡-香港-中国-加拿大（即将开业）-美国阿拉伯联合酋长国（即将开业）`,
  },
  learn_more: '学到更多',

  //! ----------------------------------------------------------------------------
  // ------------------------------ CONTACT US PAGE ----------------------------------
  contactUsPage: {
    title: '中心刊物 2017',
    subTitle: `一个友好的客户支持团队，只需点击一下即可`,
    formTitle: `请随时与我们联系，我们将很高兴收到您的来信`,
    formLabels: {
      name: '姓名',
      email: '电子邮件',
      subject: '主题',
      message: '在此处输入您的信息。',
    },
    locations: {
      // 0: `Dubai%Shaikh Zayed Road, H Tower, 2501%(971) 1234-567`,
      0: `巴西`,
      1: `哥伦比亚`,
      2: `瑞士`,
      3: `火鸡`,
    },
  },
};

export default ca;
