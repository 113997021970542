// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------
const PRODUCT_DEFAULT_TEXT = `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum`;
const companyName = `South North America Export & Investment`;
const en = {
  // ------------------------------- HEADER LINKS --------------------------------------------
  home: 'Home',
  about: 'About',
  contactUs: 'Contact Us',
  services: 'Services',
  // ------------------------------- FOOTER --------------------------------------------
  shortDesc: `${companyName} branched originally from the mothers companies which established in Texas- United States in 1987 and established in Texas 1992. Both corporations experienced in the all kinds of food distribution`,
  sitemap: 'site map',
  product: 'Products',
  service: 'Services',
  copyWrite: '© 2022. All rights reserved',
  email: 'Email',
  // ------------------------------- Other --------------------------------------------
  submit: 'Submit Now',
  weCanHelp: 'We Can Help',
  banksConnection: 'Banks Connection',

  //! ------------------------------- HOME PAGE --------------------------------------------
  // ------------------------------- Hero --------------------------------------------
  hero: {
    title: companyName,
    // subTitle: 'A future of collaborating and creating the only way we know how. together',
    subTitle: `We are all over the world !!`,
  },
  // ------------------------------- Our Products --------------------------------------------
  products: {
    title: `A wide range of products & Services`,
    subTitle: `We have established ourselves as a reliable supplier in the region. With special emphasis on quality and schedule, we strive continuously to provide the best products and services and are committed to establishing strong, long-lasting and mutually beneficial relationships with our clients and customers. Quality of the products and perfect services are the major Commitment of our company enhance its reputation and create the right Environment for farther growth and profitability. Our main food products like Brazilian sugar, soybeans, rice, cooking oil, Wheat, corn, flour, coffee, and cardamom, etc.`,
    grains: { title: 'Grains', desc: PRODUCT_DEFAULT_TEXT },
    brazilian_sugar: { title: 'Brazilian Sugar', desc: PRODUCT_DEFAULT_TEXT },
    livestock: { title: 'Livestock & Diary Products', desc: PRODUCT_DEFAULT_TEXT },
    vegetables: { title: 'Vegetables & Fruits', desc: PRODUCT_DEFAULT_TEXT },
    coffee: { title: 'Coffee, Chocolate & Cardamom', desc: PRODUCT_DEFAULT_TEXT },
    mining: { title: 'Mining', desc: PRODUCT_DEFAULT_TEXT },
    petroleum: { title: 'Petroleum & Petrochemical', desc: PRODUCT_DEFAULT_TEXT },
    oil: { title: 'Plant Sources Oil', desc: PRODUCT_DEFAULT_TEXT },
    finance: {
      title: 'Finance & Investment',
      cards: {
        0: {
          title: `Are you facing financial issues? \n Are you struggling to finance your project?`,
          focusText: `$100,000 to $25,000,000,000`,
          supportingText: `ready for you project with 0% interest within 72 hours \n 0% interest up to 10 years`,
        },
        1: {
          title: `Do you need cash in advance for your instrument?! \n Do you need to monetize your instrument?!!`,
          focusText: `Sblc, dlc, lc, bg, mtn, ltn and sovereign guarantee`,
          supportingText: ``,
        },
        2: {
          title: `Do you have treasury boxes?`,
          focusText: `1924 german \n dragon \n zimbabwe \niraqi dinar`,
          supportingText: ``,
        },
      },
    },
    logistics: { title: 'Logistics & Transportation', desc: PRODUCT_DEFAULT_TEXT },
    certificates: 'Certificates',
  },
  // ------------------------------- Our Strength --------------------------------------------
  strength: {
    title: `Our Strength`,
    subTitle: `Our corporate strengths lie in strategic and organic relationships to fully apply the advantages of Sumitomo
    Corporation Group's assets, resources and network. We aim to be the best partner, while providing proactive proposals and comprehensive support in all aspects of business for metal products supply and investment project, by both integrated corporate strength and our expertise`,
    items: {
      0: `Various achievements / certificates`,
      1: `5 world class banks`,
      2: `State of art processing units`,
      3: `40,000+ tons trading annually`,
      4: `10+ Million group turnover`,
      5: `500+ valued customers`,
    },
    secondaryText: `${companyName} has cooperated with world class banks to secure international & facilitate financial transactions, some of which are: Citi, WElls Fargo, JPMorgan, BBVA, Santander, HSBC, Barclays, UBS,Credit Suisse, DUTCHE BANK, UOB, DBS`,
  },
  // ------------------------------- How we Stand Out --------------------------------------------
  standOut: {
    title: `What differentiate us !!`,
    subTitle: `How we stand out from the rest..`,
    items: {
      0: `Direct shipment to the buyer both in bulk and bagged cargo`,
      1: `Handle of export offer efficiently ranging from container load to charter credit`,
      2: `Arranging credit facility ranging from 60 to 180 days on international credit norms`,
      3: `Highly competitive price to direct user traders and sale by trader`,
      4: `Large warehousing facility at plants and ports`,
    },
  },
  // ------------------------------- About us (We are allover the world) --------------------------------------------
  allOverTheWorld: {
    title: 'About us',
    subTitle: `Our offices around the world are in : Brazil (Main Branch) - Colombia - Mexico - Chile - Argentina - Panama - Costa Rica - United States - United Kingdom - Switzerland - Turkey - Singapore - Hongkong - China - Canada (Opening Soon) - United Arab Emirates (Opening Soon)`,
  },
  learn_more: 'Learn More',

  //! ----------------------------------------------------------------------------
  // ------------------------------ CONTACT US PAGE ----------------------------------
  contactUsPage: {
    title: 'Contact Us',
    subTitle: `A friendly customer support team that's always just a click away`,
    formTitle: `Feel free to contact us, We'll be glad to hear from you`,
    formLabels: {
      name: 'name',
      email: 'email',
      subject: 'subject',
      message: 'enter your message here.',
    },
    locations: {
      // 0: `Dubai%Shaikh Zayed Road, H Tower, 2501%(971) 1234-567`,
      0: `Brazil`,
      1: `Colombia`,
      2: `Switzerland`,
      3: `Turkey`,
    },
  },
};

export default en;
