import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  currentPage: '/',
};

const slice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    // UPDATE CURRENT PAGE
    updateCurrentPageSuccess(state, action) {
      const { toPage } = action.payload;
      state.currentPage = toPage;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { updateCurrentPageSuccess } = slice.actions;

// -------------------------------------------------
export function updateCurrentPage(page) {
  dispatch(slice.actions.updateCurrentPageSuccess({ toPage: page }));
}
