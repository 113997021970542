import { Link as RouterLink } from 'react-router-dom';
import { paramCase } from 'change-case';
// @mui
import { styled } from '@mui/material/styles';
import { Grid, Link, Divider, Container, Typography, Stack } from '@mui/material';
// routes
import { PATH_PAGE } from '../../routes/paths';
// components
import Logo from '../../components/Logo';
import SocialsButton from '../../components/SocialsButton';
import useLocales from '../../hooks/useLocales';

// ----------------------------------------------------------------------

const LINKS = [
  {
    headline: 'sitemap',
    children: [
      { name: 'home', href: PATH_PAGE.home },
      { name: 'about', href: PATH_PAGE.about },
      { name: 'contactUs', href: PATH_PAGE.contact },
    ],
  },
  {
    headline: 'product',
    children: [
      { name: 'grains', href: PATH_PAGE.view(paramCase('grains')) },
      { name: 'brazilian_sugar', href: PATH_PAGE.view(paramCase('brazilian_sugar')) },
      { name: 'livestock', href: PATH_PAGE.view(paramCase('livestock')) },
      { name: 'vegetables', href: PATH_PAGE.view(paramCase('vegetables')) },
      { name: 'coffee', href: PATH_PAGE.view(paramCase('Coffee')) },
    ],
  },
  {
    headline: 'service',
    children: [
      { name: 'mining', href: PATH_PAGE.view(paramCase('mining')) },
      { name: 'petroleum', href: PATH_PAGE.view(paramCase('petroleum')) },
      { name: 'finance', href: PATH_PAGE.view(paramCase('finance')) },
      { name: 'logistics', href: PATH_PAGE.view(paramCase('logistics')) },
    ],
  },
  {
    headline: 'email',
    children: [
      { name: 'info@snamerica-ei.com', href: '#' },
      // { name: 'Texas, 359  Hidden Valley Road', href: '#' },
    ],
  },
];

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

export default function MainFooter() {
  const { translate } = useLocales();
  return (
    <RootStyle>
      <Divider />

      <Container sx={{ pt: 10 }}>
        <Grid
          container
          justifyContent={{ xs: 'center', md: 'space-between' }}
          sx={{ textAlign: { xs: 'center', md: 'left' } }}
        >
          <Grid item xs={12} sx={{ mb: 3 }}>
            <Logo sx={{ mx: { xs: 'auto', md: 'inherit' } }} />
          </Grid>

          <Grid item xs={8} md={4}>
            <Typography variant="body2" sx={{ pr: { md: 5 } }}>
              {translate('shortDesc')}
            </Typography>

            <Stack
              direction="row"
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              sx={{ mt: 5, mb: { xs: 5, md: 0 } }}
            >
              <SocialsButton sx={{ mx: 0 }} />
            </Stack>
          </Grid>

          <Grid item xs={12} md={8}>
            <Stack spacing={5} direction={{ xs: 'column', md: 'row' }} justifyContent="space-between">
              {LINKS.map((list) => (
                <Stack key={list.headline} spacing={2}>
                  <Typography component="p" variant="overline">
                    {translate(list.headline)}
                    {/* {list.headline} */}
                  </Typography>

                  {list.children.map((link) => (
                    <Link
                      to={link.href}
                      key={link.name}
                      color="inherit"
                      variant="body2"
                      component={RouterLink}
                      sx={{ display: 'block' }}
                    >
                      {list.headline === 'sitemap' || list.headline === 'email'
                        ? translate(`${link.name}`)
                        : translate(`products.${link.name.toLowerCase()}.title`)}
                    </Link>
                  ))}
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>

        <Typography
          component="p"
          variant="body2"
          sx={{
            mt: 10,
            pb: 5,
            fontSize: 13,
            textAlign: { xs: 'center', md: 'left' },
          }}
        >
          {translate('copyWrite')}
        </Typography>
      </Container>
    </RootStyle>
  );
}
