// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------
const PRODUCT_DEFAULT_TEXT = `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum`;
const companyName = `Exportaciones e inversiones de América del Sur del Sur`;
const es = {
  // ------------------------------- HEADER LINKS --------------------------------------------
  home: 'Hogar',
  about: 'Acerca de',
  contactUs: 'Contáctenos',
  services: 'Servicios',
  // ------------------------------- FOOTER --------------------------------------------
  shortDesc: `${companyName} se ramificó originalmente de las empresas matrices que se establecieron en Texas, Estados Unidos en 1987 y se establecieron en Texas en 1992. Ambas corporaciones tienen experiencia en todo tipo de distribución de alimentos`,
  sitemap: 'mapa del sitio',
  product: 'Productos',
  service: 'Servicios',
  copyWrite: '© 2022. Todos los derechos reservados',
  email: 'Email',
  // ------------------------------- OTHER --------------------------------------------
  submit: 'Aplique ahora',
  banksConnection: 'Conexión de bancos',
  weCanHelp: 'Nosotras podemos ayudar',
  //! ------------------------------- HOME PAGE --------------------------------------------
  // ------------------------------- Hero --------------------------------------------
  hero: {
    title: companyName,
    // subTitle: 'A future of collaborating and creating the only way we know how. together',
    subTitle: `¡¡Estamos en todo el mundo!!`,
  },
  // ------------------------------- Our Products --------------------------------------------
  products: {
    title: `Una amplia gama de productos y servicios`,
    subTitle: `Nos hemos consolidado como un proveedor confiable en la región. Con especial énfasis en la calidad y el tiempo, nos esforzamos continuamente para brindar los mejores productos y servicios y estamos comprometidos a establecer relaciones sólidas, duraderas y mutuamente beneficiosas con nuestros clientes y clientes. La calidad de los productos y los servicios perfectos son el principal compromiso de nuestra empresa para mejorar su reputación y crear el entorno adecuado para un mayor crecimiento y rentabilidad. Nuestros principales productos alimenticios como el azúcar brasileño, la soja, el arroz, el aceite de cocina, el trigo, el maíz, la harina, el café y el cardamomo, etc.`,
    grains: { title: 'Granos', desc: PRODUCT_DEFAULT_TEXT },
    brazilian_sugar: { title: 'Azúcar de Brasil', desc: PRODUCT_DEFAULT_TEXT },
    livestock: { title: 'Ganadería y Productos Lácteos', desc: PRODUCT_DEFAULT_TEXT },
    vegetables: { title: 'Verduras y Frutas', desc: PRODUCT_DEFAULT_TEXT },
    coffee: { title: 'Café, Chocolate y Cardamomo', desc: PRODUCT_DEFAULT_TEXT },
    mining: { title: 'Minería', desc: PRODUCT_DEFAULT_TEXT },
    petroleum: { title: 'Petróleo y petroquímica', desc: PRODUCT_DEFAULT_TEXT },
    oil: { title: 'Aceite de origen vegetal', desc: PRODUCT_DEFAULT_TEXT },
    finance: {
      title: 'Finanzas e Inversiones',
      cards: {
        0: {
          title: `¿Está enfrentando problemas financieros? \n ¿Tiene dificultades para financiar su proyecto?`,
          focusText: `$100,000 a $25,000,000,000`,
          supportingText: `listo para su proyecto con 0% de interés dentro de las 72 horas \n 0% de interés hasta 10 años`,
        },
        1: {
          title: `¿Necesitas dinero en efectivo por adelantado para tu instrumento? \n  Necesitas monetizar tu instrumento?!!`,
          focusText: `Sblc, dlc, lc, bg, mtn, ltn y garantía soberana`,
          supportingText: ``,
        },
        2: {
          title: ` ¿Tiene cajas del tesoro?`,
          focusText: `1924 alemán \n continuar \n Zimbabue \n dinar iraquí`,
          supportingText: ``,
        },
      },
    },
    logistics: { title: 'Logística y Transporte', desc: PRODUCT_DEFAULT_TEXT },
    certificates: 'Certificados',
  },
  // ------------------------------- Our Strength --------------------------------------------
  strength: {
    title: `Nuestra fuerza`,
    subTitle: `Nuestras fortalezas corporativas se encuentran en las relaciones estratégicas y orgánicas para aplicar plenamente las ventajas de los activos, los recursos y la red de Sumitomo Corporation Group. Nuestro objetivo es ser el mejor socio, al mismo tiempo que brindamos propuestas proactivas y un apoyo integral en todos los aspectos del negocio para el suministro de productos metálicos y proyectos de inversión, tanto por nuestra fuerza corporativa integrada como por nuestra experiencia.`,
    items: {
      0: `Varios logros/certificados`,
      1: `5 bancos de clase mundial`,
      2: 'Unidades de procesamiento de última generación',
      3: '40,000+ toneladas negociadas anualmente',
      4: `Más de 10 millones de facturación del grupo`,
      5: `Más de 500 clientes valiosos`,
    },
    secondaryText: `${companyName} ha cooperado con bancos de clase mundial para asegurar y facilitar transacciones financieras internacionales, algunos de los cuales son: Citi, WElls Fargo, JPMorgan, BBVA, Santander, HSBC, Barclays, UBS, Credit Suisse, DUTCHE BANK, UOB, DBS`,
  },
  // ------------------------------- How we Stand Out --------------------------------------------
  standOut: {
    title: `Que nos diferencian!!`,
    subTitle: `Cómo nos destacamos del resto..`,
    items: {
      0: `Envío directo al comprador tanto a granel como en saco`,
      1: 'Manejo de la oferta de exportación de manera eficiente que va desde la carga del contenedor hasta el crédito de fletamento',
      2: 'Organización de líneas de crédito que van desde 60 a 180 días en las normas internacionales de crédito',
      3: `Precio altamente competitivo para usuarios directos comerciantes y venta por comerciante`,
      4: 'Grandes instalaciones de almacenamiento en plantas y puertos',
    },
  },
  // ------------------------------- About us (We are allover the world) --------------------------------------------
  allOverTheWorld: {
    title: 'Sobre nosotras',
    subTitle: `Nuestras oficinas alrededor del mundo están en: Brasil (sucursal principal) - Colombia - México - Chile - Argentina - Panamá - Costa Rica - Estados Unidos - Reino Unido - Suiza - Turquía - Singapur - Hong Kong - China - Canadá (apertura próximamente) - Estados Unidos Emiratos Árabes (Apertura Próximamente)`,
  },
  learn_more: 'Aprende más',

  //! ----------------------------------------------------------------------------
  // ------------------------------ CONTACT US PAGE ----------------------------------
  contactUsPage: {
    title: 'Contact Us',
    subTitle: `Un amable equipo de atención al cliente que siempre está a solo un clic de distancia`,
    formTitle: `No dude en ponerse en contacto con nosotros, estaremos encantados de saber de usted`,
    formLabels: {
      name: 'nombre',
      email: 'Email',
      subject: 'Sujeta',
      message: 'introduzca su mensaje aquí.',
    },
    locations: {
      // 0: `Dubai%Shaikh Zayed Road, H Tower, 2501%(971) 1234-567`,
      0: `Brasil`,
      1: `Colombia`,
      2: `Suiza`,
      3: `Turquía`,
    },
  },
};

export default es;
