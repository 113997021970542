import { useEffect } from 'react';
import { useLocation, Outlet, useNavigate } from 'react-router-dom';
// @mui
import { Box, Stack } from '@mui/material';
// components
import MainFooter from './MainFooter';
import MainHeader from './MainHeader';
import useLocales from '../../hooks/useLocales';

// ----------------------------------------------------------------------

export default function MainLayout() {
  const { pathname } = useLocation();
  const { onChangeLang, allLangs, langStorage } = useLocales();
  const navigate = useNavigate();

  useEffect(() => {
    // Get Language value from URL and change Current Language (On Page Load)
    const urlLang = pathname.slice(1, 3);
    const langIsAvailable = allLangs.some((lang) => lang.value === urlLang);
    langIsAvailable ? onChangeLang(urlLang) : navigate(`/${langStorage || 'en'}`);
  }, []);

  return (
    <Stack sx={{ minHeight: 1 }}>
      <MainHeader />
      <Outlet />
      <Box sx={{ flexGrow: 1 }} />
      <MainFooter />
    </Stack>
  );
}
