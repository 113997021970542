// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------
const PRODUCT_DEFAULT_TEXT = `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum`;
const companyName = `Sud Amérique du Nord Exportation & Investissement`;
const fr = {
  // ------------------------------- HEADER LINKS --------------------------------------------
  home: 'Maison',
  about: 'À propos de',
  contactUs: 'Nous contacter',
  services: 'Prestations de service',
  // ------------------------------- FOOTER --------------------------------------------
  shortDesc: `${companyName} ramifiée à l'origine des sociétés mères qui se sont établies au Texas-États-Unis en 1987 et établies au Texas en 1992. Les deux sociétés ont de l'expérience dans toutes sortes de distribution alimentaire`,
  sitemap: 'plan du site',
  product: 'Des produits',
  service: 'Prestations de service',
  copyWrite: '© 2022. Tous droits réservés',
  email: 'E-mail',
  // ------------------------------- OTHER --------------------------------------------
  submit: 'Soumettre maintenant',
  weCanHelp: 'Nous pouvons aider',
  banksConnection: 'Connexion aux banques',

  //! ------------------------------- HOME PAGE --------------------------------------------
  // ------------------------------- Hero --------------------------------------------
  hero: {
    title: companyName,
    // subTitle: 'A future of collaborating and creating the only way we know how. together',
    subTitle: `Nous sommes partout dans le monde !!`,
  },
  // ------------------------------- Our Products --------------------------------------------
  products: {
    title: `Une large gamme de produits & services`,
    subTitle: `Nous nous sommes imposés comme un fournisseur fiable dans la région. Avec un accent particulier sur la qualité et le calendrier, nous nous efforçons continuellement de fournir les meilleurs produits et services et nous nous engageons à établir des relations solides, durables et mutuellement bénéfiques avec nos clients et clients. La qualité des produits et des services parfaits sont le principal engagement de notre entreprise à améliorer sa réputation et à créer le bon environnement pour une croissance et une rentabilité accrues. Nos principaux produits alimentaires comme le sucre brésilien, le soja, le riz, l'huile de cuisson, le blé, le maïs, la farine, le café et la cardamome, etc.`,
    grains: { title: 'Céréales', desc: PRODUCT_DEFAULT_TEXT },
    brazilian_sugar: { title: 'Sucre Brésilien', desc: PRODUCT_DEFAULT_TEXT },
    livestock: { title: 'Bétail et produits laitiers', desc: PRODUCT_DEFAULT_TEXT },
    vegetables: { title: 'Légumes & Fruits', desc: PRODUCT_DEFAULT_TEXT },
    coffee: { title: 'Café, Chocolat & Cardamome', desc: PRODUCT_DEFAULT_TEXT },
    mining: { title: 'Exploitation minière', desc: PRODUCT_DEFAULT_TEXT },
    petroleum: { title: 'Pétrole et Pétrochimie', desc: PRODUCT_DEFAULT_TEXT },
    oil: { title: 'Huile de Sources Végétales', desc: PRODUCT_DEFAULT_TEXT },
    finance: {
      title: 'Finances & Investissement',
      cards: {
        0: {
          title: `Êtes-vous confronté à des problèmes financiers? \n Avez-vous du mal à financer votre projet?`,
          focusText: `100000$ à 25000000000$`,
          supportText: "prêt pour votre projet avec 0% d'intérêt dans les 72heures \n 0% d'intérêt jusqu'à 10 ans",
        },
        1: {
          title: `Avez-vous besoin d'argent à l'avance pour votre instrument ?! \n Avez-vous besoin de monétiser votre instrument ?!!`,
          focusText: `Sblc, dlc, lc, bg, mtn, ltn et garantie souveraine`,
          supportText: ``,
        },
        2: {
          title: `Avez-vous des caisses?`,
          focusText: `1924 allemand \n dragon \n zimbabwe \niraqi dinar`,
          supportText: ``,
        },
      },
    },
    logistics: { title: 'Logistique & Transport', desc: PRODUCT_DEFAULT_TEXT },
    certificates: 'Certificats',
  },
  // ------------------------------- Our Strength --------------------------------------------
  strength: {
    title: `Notre force`,
    subTitle: `Nos forces d'entreprise résident dans des relations stratégiques et organiques pour appliquer pleinement les avantages des actifs, des ressources et du réseau de Sumitomo Corporation Group. Nous visons à être le meilleur partenaire, tout en fournissant des propositions proactives et un soutien complet dans tous les aspects de l'entreprise pour l'approvisionnement en produits métalliques et le projet d'investissement, à la fois par la force intégrée de l'entreprise et notre expertise`,
    items: {
      0: `Diverses réalisations / certificats`,
      1: `5 banques de classe mondiale`,
      2: 'Unités de traitement de pointe',
      3: `40 000+ tonnes échangées chaque année`,
      4: `10+ millions de chiffre d'affaires du groupe`,
      5: '500+ clients appréciés',
    },
    secondaryText: `${companyName} a coopéré avec des banques de classe mondiale pour sécuriser et faciliter les transactions financières internationales, dont certaines sont : Citi, WElls Fargo, JPMorgan, BBVA, Santander, HSBC, Barclays, UBS, Credit Suisse, DUTCHE BANK, UOB, DBS`,
  },
  // ------------------------------- How we Stand Out --------------------------------------------
  standOut: {
    title: `Ce qui nous différencie !!`,
    subTitle: `Comment nous nous démarquons des autres..`,
    items: {
      0: `Expédition directe à l'acheteur en vrac et en sac`,
      1: `Gestion efficace de l'offre d'exportation allant du chargement de conteneurs au crédit d'affrètement`,
      2: `Facilité de crédit allant de 60 à 180jours selon les normes internationales de crédit`,
      3: `Prix très compétitif pour les commerçants utilisateurs directs et vente par commerçant`,
      4: `Grande installation d'entreposage dans les usines et les ports`,
    },
  },
  // ------------------------------- About us (We are allover the world) --------------------------------------------
  allOverTheWorld: {
    title: 'À propos de nous',
    subTitle: `Nos bureaux dans le monde sont: Brésil (succursale principale) - Colombie - Mexique - Chili - Argentine - Panama - Costa Rica - États-Unis - Royaume-Uni - Suisse - Turquie - Singapour - Hongkong - Chine - Canada (Ouverture prochaine ) - Émirats Arabes Unis (Ouverture prochaine)`,
  },
  learn_more: 'En savoir plus',

  //! ----------------------------------------------------------------------------
  // ------------------------------ CONTACT US PAGE ----------------------------------
  contactUsPage: {
    title: 'Contactez-nous',
    subTitle: `Une équipe d'assistance client amicale toujours à portée de clic`,
    formTitle: `N'hésitez pas à nous contacter, nous serons heureux d'avoir de vos nouvelles`,
    formLabels: {
      name: 'nom',
      email: 'e-mail',
      subject: 'sujet',
      message: 'entrez votre message ici.',
    },
    locations: {
      0: 'Brésil',
      1: 'Colombie',
      2: 'Suisse',
      3: 'Turquie',
    },
  },
};

export default fr;
