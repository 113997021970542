// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------
const PRODUCT_DEFAULT_TEXT = `لوريم إيبسوم(Lorem Ipsum) هو ببساطة نص شكلي (بمعنى أن الغاية هي الشكل وليس المحتوى) ويُستخدم في صناعات المطابع ودور النشر. كان لوريم إيبسوم ولايزال المعيار للنص الشكلي منذ القرن الخامس عشر عندما قامت مطبعة مجهولة برص مجموعة من الأحرف بشكل عشوائي أخذتها من نص، لتكوّن كتيّب بمثابة دليل أو مرجع شكلي لهذه الأحرف. خمسة قرون من الزمن لم تقضي على هذا النص، بل انه حتى صار مستخدماً وبشكله الأصلي في الطباعة والتنضيد الإلكتروني. انتشر بشكل كبير في ستينيّات هذا القرن مع إصدار رقائق "ليتراسيت" (Letraset) البلاستيكية تحوي مقاطع من هذا النص، وعاد لينتشر مرة أخرى مؤخراَ مع ظهور برامج النشر الإلكتروني مثل "ألدوس بايج مايكر" (Aldus PageMaker) والتي حوت أيضاً على نسخ من نص لوريم إيبسوم.`;
const companyName = `جنوب شمال أمريكا للتصدير والإستثمار`;

const ar = {
  weCanHelp: 'نستطيع المساعدة',
  banksConnection: 'البنوك التي نتعامل معها',
  // ------------------------------- FOOTER --------------------------------------------
  shortDesc: `${companyName} تفرعت في الأصل من الشركات الأم التي تأسست في تكساس بالولايات المتحدة في عام 1987 وتأسست في تكساس 1992. كلا الشركتين من ذوي الخبرة في جميع أنواع توزيع المواد الغذائية`,
  sitemap: 'خريطة الموقع',
  product: 'منتجاتنا',
  service: 'خدماتنا',
  copyWrite: '© 2022. جميع الحقوق محفوظة',
  email: `البريد الإلكتروني`,
  // ------------------------------- HEADER LINKS --------------------------------------------
  home: 'الرئيسية',
  about: 'من نحن',
  services: 'خدماتنا',
  contactUs: 'تواصل معنا',
  submit: 'ارسل الآن',
  hero: {
    title: companyName,
    subTitle: `نحن في كل مكان !!`,
  },
  products: {
    title: `مجموعة واسعة من المنتجات و الخدمات`,
    subTitle: `لقد أنشأنا أنفسنا كمورد موثوق به في المنطقة. مع التركيز بشكل خاص على الجودة والجدول الزمني ، نسعى باستمرار لتقديم أفضل المنتجات والخدمات ونلتزم بإقامة علاقات قوية وطويلة الأمد وذات منفعة متبادلة مع عملائنا وعملائنا. جودة المنتجات والخدمات المثالية هي الالتزام الرئيسي لشركتنا لتعزيز سمعتها وخلق البيئة المناسبة لتحقيق نمو وربحية أكبر. منتجاتنا الغذائية الرئيسية مثل السكر البرازيلي وفول الصويا والأرز وزيت الطهي والقمح والذرة والدقيق والقهوة والهيل ، إلخ.`,
    grains: { title: 'الحبوب', desc: PRODUCT_DEFAULT_TEXT },
    brazilian_sugar: { title: 'سكر برازيلي', desc: PRODUCT_DEFAULT_TEXT },
    livestock: { title: 'الثروة الحيوانية ومنتجات الألبان', desc: PRODUCT_DEFAULT_TEXT },
    vegetables: { title: 'الخضار والفواكه', desc: PRODUCT_DEFAULT_TEXT },
    coffee: { title: 'القهوة والشوكولاتة والهيل', desc: PRODUCT_DEFAULT_TEXT },
    oil: { title: 'زيت المصادر النباتية', desc: PRODUCT_DEFAULT_TEXT },
    mining: { title: 'التعدين', desc: PRODUCT_DEFAULT_TEXT },
    petroleum: { title: 'البترول والبتروكيماويات', desc: PRODUCT_DEFAULT_TEXT },
    finance: {
      title: 'التمويل والاستثمار',
      cards: {
        0: {
          title: `هل تواجه مشاكل مالية؟ \n هل تكافح من أجل تمويل مشروعك؟`,
          focusText: `100،000 إلى 25،000،000،000 دولار أمريكي`,
          supportingText: `0٪ فائدة تصل إلى 10 سنوات \n جاهزة لمشروعك بفائدة 0٪ خلال 72 ساعة`,
        },
        1: {
          title: `هل تحتاج نقدا مقدما لأداتك ؟! /n هل تحتاج إلى تسييل ائتمان بنكي؟ !!`,
          focusText: `Sblc و dlc و lc و bg و mtn و ltn و ضمان سيادي`,
          supportingText: ``,
        },
        2: {
          title: `هل لديك صناديق خزينة؟`,
          focusText: `1924 الماني 
       الصيني
        زمبابوي 
        الدينار العراقي 
        `,
          supportingText: ``,
        },
      },
    },
    logistics: { title: 'النقل والخدمات اللوجستية', desc: PRODUCT_DEFAULT_TEXT },
    certificates: 'الشهادات',
  },
  strength: {
    title: `قوتنا`,
    subTitle: `تكمن قوة شركتنا في العلاقات الإستراتيجية والعضوية لتطبيق مزايا سوميتومو بالكامل
    أصول وموارد وشبكة مجموعة كوربوريشن. نهدف إلى أن نكون الشريك الأفضل ، مع تقديم استباقي
    مقترحات ودعم شامل في جميع جوانب الأعمال لتوريد المنتجات المعدنية والاستثمار
    المشروع ، من خلال قوة الشركة المتكاملة وخبرتنا`,
    items: {
      0: `إنجازات / شهادات مختلفة`,
      1: `5 بنوك عالمية المستوى`,
      2: `أحدث وحدات المعالجة`,
      3: `40.000+ طن يتم تداولها سنويًا`,
      4: `10+ مليون مجموعة دوران`,
      5: `500+ من العملاء الكرام`,
    },
    secondaryText: `${companyName} تعاونت مع بنوك عالمية لتأمين المعاملات المالية الدولية وتسهيلها ، ومنها: Citi و WElls Fargo و JPMorgan و BBVA و Santander و HSBC و Barclays و UBS و Credit Suisse و DUTCHE BANK و UOB و DBS`,
  },
  standOut: {
    title: `ما يميزنا !!`,
    subTitle: `كيف نتميز عن البقية ..`,
    items: {
      0: 'الشحن المباشر إلى المشتري سواء في البضائع السائبة أو المعبأة',
      1: 'التعامل مع عرض التصدير بكفاءة تتراوح من تحميل الحاوية إلى ائتمان الاستئجار',
      2: 'ترتيب تسهيلات ائتمانية تتراوح من 60 إلى 180 يومًا وفقًا لمعايير الائتمان الدولية',
      3: 'سعر تنافسي للغاية لتوجيه تجار المستخدمين والبيع عن طريق التاجر',
      4: 'مرفق تخزين كبير في المصانع والموانئ',
    },
  },
  allOverTheWorld: {
    title: `نبذة عنا`,
    subTitle: `مكاتبنا حول العالم موجودة في: البرازيل (الفرع الرئيسي) - كولومبيا - المكسيك - تشيلي - الأرجنتين - بنما - كوستاريكا - الولايات المتحدة - المملكة المتحدة - سويسرا - تركيا - سنغافورة - هونغ كونغ - الصين - كندا (الافتتاح قريبًا ) - الإمارات العربية المتحدة (الافتتاح قريباً) `,
  },
  learn_more: 'تعرف على المزيد',
  // ----------------------------------------------------------------------------
  // ------------------------------ Contact us Page ----------------------------------
  contactUsPage: {
    title: `تواصل معنا`,
    subTitle: `فريق دعم عملاء ودود على بعد نقرة واحدة دائمًا`,
    formTitle: `لا تتردد في الاتصال بنا ، يسعدنا أن نسمع منك`,
    formLabels: {
      name: 'الاسم',
      email: 'البريد الالكتروني',
      subject: 'الموضوع',
      message: 'أدخل رسالتك هنا.',
    },
    locations: {
      // 0: `دبي% شارع الشيخ زايد ، برج ذا اتش ، 2501% (971) 1234-567`,
      0: `برازيل`,
      1: `كولومبيا`,
      2: `سويسرا`,
      3: `تركيا`,
    },
  },
};

export default ar;
