// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------
const PRODUCT_DEFAULT_TEXT = `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum`;
const companyName = `Sul América do Norte Exportação e Investimento`;
const pt = {
  // ------------------------------- HEADER LINKS --------------------------------------------
  home: 'Casa',
  about: 'Sobre',
  contactUs: 'Contate-nos',
  services: 'Serviços',
  // ------------------------------- FOOTER --------------------------------------------
  shortDesc: `${companyName} ramificada originalmente das empresas mães que se estabeleceram no Texas-Estados Unidos em 1987 e no Texas em 1992. Ambas as empresas com experiência em todos os tipos de distribuição de alimentos`,
  sitemap: 'Mapa do site',
  product: 'Produtos',
  service: 'Serviços',
  copyWrite: '© 2022. Todos os direitos reservados',
  // ------------------------------- OTHER --------------------------------------------
  submit: 'Envie agora',
  weCanHelp: 'Nós podemos ajudar',
  banksConnection: 'Conexão de Bancos',

  //! ------------------------------- HOME PAGE --------------------------------------------
  // ------------------------------- Hero --------------------------------------------
  hero: {
    title: companyName,
    // subTitle: 'A future of collaborating and creating the only way we know how. together',
    subTitle: `Estamos em todo o mundo!!`,
  },
  // ------------------------------- Our Products --------------------------------------------
  products: {
    title: `Uma vasta gama de produtos e serviços`,
    subTitle: `Nós nos estabelecemos como um fornecedor confiável na região. Com ênfase especial na qualidade e no cronograma, nos esforçamos continuamente para fornecer os melhores produtos e serviços e estamos comprometidos em estabelecer relacionamentos fortes, duradouros e mutuamente benéficos com nossos clientes e consumidores. A qualidade dos produtos e serviços perfeitos são o grande compromisso da nossa empresa para melhorar a sua reputação e criar o ambiente certo para um maior crescimento e rentabilidade. Nossos principais produtos alimentícios como açúcar brasileiro, soja, arroz, óleo de cozinha, trigo, milho, farinha, café e cardamomo, etc.`,
    grains: { title: 'Grãos', desc: PRODUCT_DEFAULT_TEXT },
    brazilian_sugar: { title: 'Açúcar Brasileiro', desc: PRODUCT_DEFAULT_TEXT },
    livestock: { title: 'Pecuária e produtos lácteos', desc: PRODUCT_DEFAULT_TEXT },
    vegetables: { title: 'Legumes e frutas', desc: PRODUCT_DEFAULT_TEXT },
    coffee: { title: 'Café, Chocolate e Cardamomo', desc: PRODUCT_DEFAULT_TEXT },
    mining: { title: 'Mineração', desc: PRODUCT_DEFAULT_TEXT },
    petroleum: { title: 'Petróleo e Petroquímica', desc: PRODUCT_DEFAULT_TEXT },
    oil: { title: 'Óleo de fontes vegetais', desc: PRODUCT_DEFAULT_TEXT },
    finance: {
      title: 'Finanças e Investimento',
      cards: {
        0: {
          title: `Você está enfrentando problemas financeiros? \n Você está com dificuldades para financiar seu projeto?`,
          focusText: `$100.000 a $25.000.000.000`,
          supportText: `pronto para seu projeto com 0% de juros em 72 horas \n 0% de juros até 10 anos`,
        },
        1: {
          title: `Você precisa de dinheiro adiantado para o seu instrumento?! \n Você precisa monetizar seu instrumento?!!`,
          focusText: `Sblc, dlc, lc, bg, mtn, ltn e garantia soberana`,
          supportText: ``,
        },
        2: {
          title: `Você tem caixas do tesouro?`,
          focusText: `1924 alemão \n dragão \n zimbabwe \niraqi dinar`,
          supportText: ``,
        },
      },
    },
    logistics: { title: 'Logística e Transporte', desc: PRODUCT_DEFAULT_TEXT },
    certificates: 'Certificados',
  },
  // ------------------------------- Our Strength --------------------------------------------
  strength: {
    title: `Nossa força`,
    subTitle: `Nossos pontos fortes corporativos estão em relacionamentos estratégicos e orgânicos para aplicar plenamente as vantagens da Sumitomo
    Ativos, recursos e rede do Grupo Corporation. Nosso objetivo é ser o melhor parceiro, ao mesmo tempo em que fornecemos
    propostas e apoio abrangente em todos os aspectos do negócio para fornecimento e investimento de produtos metálicos
    projeto, tanto pela força corporativa integrada quanto pela nossa experiência`,
    items: {
      0: `Várias conquistas/certificados`,
      1: `5 bancos de classe mundial`,
      2: 'Unidades de processamento de última geração',
      3: `40.000+ toneladas negociadas anualmente`,
      4: '10+ milhões de faturamento do grupo',
      5: 'mais de 500 clientes valiosos',
    },
    secondaryText: `${companyName} colaborou com bancos de classe mundial para garantir transações financeiras internacionais e facilitar, algumas das quais são: Citi, WElls Fargo, JPMorgan, BBVA, Santander, HSBC, Barclays, UBS, Credit Suisse, DUTCHE BANK, UOB, DBS`,
  },
  // ------------------------------- How we Stand Out --------------------------------------------
  standOut: {
    title: `O que nos diferencia !!`,
    subTitle: `Como nos destacamos do resto..`,
    items: {
      0: `Remessa direta ao comprador tanto a granel quanto em carga ensacada`,
      1: `Gerenciamento da oferta de exportação de forma eficiente, desde o carregamento do contêiner até o crédito de afretamento`,
      2: `Organização de linha de crédito de 60 a 180 dias nas normas internacionais de crédito`,
      3: `Preço altamente competitivo para comerciantes usuários diretos e venda por comerciante`,
      4: `Grande instalação de armazenamento em fábricas e portos`,
    },
  },
  // ------------------------------- About us (We are allover the world) --------------------------------------------
  allOverTheWorld: {
    title: 'Sobre nós',
    subTitle: `Nossos escritórios ao redor do mundo estão em: Brasil (Sucursal principal) - Colômbia - México - Chile - Argentina - Panamá - Costa Rica - Estados Unidos - Reino Unido - Suíça - Turquia - Cingapura - Hong Kong - China - Canadá (Inauguração em breve ) - Emirados Árabes Unidos (abertura em breve)`,
  },
  learn_more: 'Saiba mais',
  // ----------------------------------------------------------------------------

  //! ------------------------------ CONTACT US PAGE ----------------------------------
  contactUsPage: {
    title: 'Onde nos encontrar?',
    subTitle: `Uma equipe de suporte ao cliente amigável que está sempre a apenas um clique de distância`,
    formTitle: `Sinta-se à vontade para entrar em contato conosco, ficaremos felizes em ouvir de você`,
    formLabels: {
      name: 'nome',
      email: 'e-mail',
      subject: 'assunto',
      message: 'digite sua mensagem aqui.',
    },
    locations: {
      // 0: `Dubai%Shaikh Zayed Road, H Tower, 2501%(971) 1234-567`,
      0: `Brasil`,
      1: 'Colômbia',
      2: 'Suíça',
      3: 'Turquia',
    },
  },
};

export default pt;
