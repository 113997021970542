// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------
const PRODUCT_DEFAULT_TEXT = `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum`;
const companyName = `Güney Kuzey Amerika İhracat ve Yatırım`;
const tr = {
  // ------------------------------- HEADER LINKS --------------------------------------------
  home: 'Ev',
  about: 'Hakkında',
  contactUs: 'Bize Ulaşın',
  services: 'Hizmetler',
  // ------------------------------- FOOTER --------------------------------------------
  shortDesc: `${companyName} 1987 yılında Teksas-Amerika Birleşik Devletleri'nde kurulan ve 1992 yılında Teksas'ta kurulan ana şirketlerden köken almıştır. Her iki şirket de her türlü gıda dağıtımı konusunda deneyimlidir.`,
  sitemap: 'site haritası',
  product: 'Ürün:% s',
  service: 'Hizmetler',
  copyWrite: '© 2022. Tüm hakları saklıdır',
  email: 'E-posta',
  // ------------------------------- OTHER --------------------------------------------
  submit: 'Şimdi gönder',
  weCanHelp: 'Yardım Edebiliriz',
  banksConnection: 'Bankalar Bağlantısı',

  //! ------------------------------- HOME PAGE --------------------------------------------
  // ------------------------------- Hero --------------------------------------------
  hero: {
    title: companyName,
    // subTitle: 'A future of collaborating and creating the only way we know how. together',
    subTitle: `Dünyanın her yerindeyiz!!`,
  },
  // ------------------------------- Our Products --------------------------------------------
  products: {
    title: `Geniş ürün ve hizmet yelpazesi`,
    subTitle: `Kendimizi bölgede güvenilir bir tedarikçi olarak kurduk. Kaliteye ve programa özel önem vererek, sürekli olarak en iyi ürün ve hizmetleri sunmaya çalışıyoruz ve müşterilerimiz ve müşterilerimizle güçlü, uzun süreli ve karşılıklı yarar sağlayan ilişkiler kurmayı taahhüt ediyoruz. Ürünlerin kalitesi ve kusursuz hizmetler, şirketimizin itibarını arttırmak ve daha fazla büyüme ve kârlılık için doğru Ortamı yaratmak için en büyük Taahhüdüdür. Brezilya şekeri, soya fasulyesi, pirinç, yemeklik yağ, Buğday, mısır, un, kahve ve kakule gibi ana gıda ürünlerimiz.`,
    grains: { title: 'Taneler', desc: PRODUCT_DEFAULT_TEXT },
    brazilian_sugar: { title: 'Brezilya Şekeri', desc: PRODUCT_DEFAULT_TEXT },
    livestock: { title: 'Hayvancılık ve Süt Ürünleri', desc: PRODUCT_DEFAULT_TEXT },
    vegetables: { title: 'Sebze ve Meyveler', desc: PRODUCT_DEFAULT_TEXT },
    coffee: { title: 'Kahve, Çikolata ve Kakule', desc: PRODUCT_DEFAULT_TEXT },
    mining: { title: 'Madencilik', desc: PRODUCT_DEFAULT_TEXT },
    petroleum: { title: 'Petrol ve Petrokimya', desc: PRODUCT_DEFAULT_TEXT },
    oil: { title: 'Bitki Kaynakları Yağı', desc: PRODUCT_DEFAULT_TEXT },
    finance: {
      title: 'Finans ve Yatırım',
      cards: {
        0: {
          title: `Mali sorunlarla mı karşı karşıyasınız? \n Projenizi finanse etmekte zorlanıyor musunuz?`,
          focusText: `100.000 ila 25.000.000.000 ABD Doları`,
          supportingText: `72 saat içinde %0 faizle projeniz hazır \n 10 yıla kadar %0 faizle`,
        },
        1: {
          title: `Enstrümanınız için peşin paraya ihtiyacınız var mı?! \n Enstrümanınızdan para kazanmanız mı gerekiyor?!!`,
          focusText: `Sblc, dlc, lc, bg, mtn, ltn ve egemen garanti`,
          supportingText: ``,
        },
        2: {
          title: `Hazine sandıklarınız var mı?`,
          focusText: `1924 alman \n ejderhası \n zimbabve \n iraki dinarı`,
          supportingText: ``,
        },
      },
    },
    logistics: { title: 'Lojistik ve Taşımacılık', desc: PRODUCT_DEFAULT_TEXT },
    certificates: 'Sertifikalar',
  },
  // ------------------------------- Our Strength --------------------------------------------
  strength: {
    title: `Bizim gücümüz`,
    subTitle: `Kurumsal gücümüz, Sumitomo'nun avantajlarını tam olarak uygulamak için stratejik ve organik ilişkilerde yatmaktadır.
    Corporation Group'un varlıkları, kaynakları ve ağı. Hem entegre kurumsal gücümüz hem de uzmanlığımızla metal ürünleri tedariği ve yatırım projesi için işin her alanında proaktif teklifler ve kapsamlı destek sağlarken en iyi ortak olmayı hedefliyoruz.`,
    items: {
      0: `Çeşitli başarılar / sertifikalar`,
      1: '5 dünya standartlarında banka',
      2: 'Son teknoloji işleme birimleri',
      3: `Yıllık 40.000+ ton ticaret`,
      4: `10+ Milyon grup cirosu`,
      5: `500+ değerli müşteri`,
    },
    secondaryText: `${companyName} uluslararası güvence altına almak ve finansal işlemleri kolaylaştırmak için birinci sınıf bankalarla işbirliği yaptı, bunlardan bazıları: Citi, WElls Fargo, JPMorgan, BBVA, Santander, HSBC, Barclays, UBS,Credit Suisse, DUTCHE BANK, UOB, DBS`,
  },
  // ------------------------------- How we Stand Out --------------------------------------------
  standOut: {
    title: `Bizi farklı kılan ne !!`,
    subTitle: `Diğerlerinden nasıl sıyrılıyoruz..`,
    items: {
      0: `Dökme ve torbalı kargoda alıcıya direkt sevkiyat`,
      1: `Konteyner yükünden charter kredisine kadar verimli bir şekilde ihracat teklifinin ele alınması`,
      2: `Uluslararası kredi normlarında 60 ila 180 gün arasında değişen kredi imkanı düzenlenmesi`,
      3: `Kullanıcı tüccarlarını yönlendirmek ve tüccar tarafından satış yapmak için son derece rekabetçi fiyat`,
      4: `Tesis ve limanlarda büyük depolama tesisi`,
    },
  },
  // ------------------------------- About us (We are allover the world) --------------------------------------------
  allOverTheWorld: {
    title: 'Hakkımızda',
    subTitle: `Dünya genelindeki ofislerimiz : Brezilya (Ana Şube) - Kolombiya - Meksika - Şili - Arjantin - Panama - Kosta Rika - Amerika Birleşik Devletleri - Birleşik Krallık - İsviçre - Türkiye - Singapur - Hongkong - Çin - Kanada (Yakında Açılıyor) - Birleşik Arap Emirlikleri (Yakında Açılıyor)`,
  },
  learn_more: 'Daha fazla bilgi edin',

  //! ----------------------------------------------------------------------------
  // ------------------------------ CONTACT US PAGE ----------------------------------
  contactUsPage: {
    title: 'Bize Ulaşın',
    subTitle: `Her zaman bir tık uzağınızda samimi bir müşteri destek ekibi`,
    formTitle: `Bizimle iletişime geçmekten çekinmeyin, sizden haber almaktan memnuniyet duyarız`,
    formLabels: {
      name: 'isim',
      email: 'e-posta',
      subject: 'ders',
      message: 'mesajınızı buraya girin.',
    },
    locations: {
      // 0: `Dubai%Shaikh Zayed Road, H Tower, 2501%(971) 1234-567`,
      0: 'Brezilya',
      1: 'Kolombiya',
      2: 'İsviçre',
      3: 'Türkiye',
    },
  },
};

export default tr;
