import { createSlice } from '@reduxjs/toolkit';
import { paramCase } from 'change-case';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------
const IMG_PATH = '/assets/img/';
const productsList = [
  {
    id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7a1',
    cover: `${IMG_PATH}grains/1.webp`,
    images: [
      `${IMG_PATH}grains/1.webp`,
      `${IMG_PATH}grains/2.webp`,
      `${IMG_PATH}grains/3.webp`,
      `${IMG_PATH}grains/4.webp`,
      `${IMG_PATH}grains/5.webp`,
      `${IMG_PATH}grains/6.webp`,
    ],
    name: 'Grains',

    category: 'Grains',
    items: ['RICE', 'SOYBEANS', 'CORN', 'LENTILS', 'CHICKPEAS', 'WHEAT', 'FLOUR'],
  },
  {
    id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7a2',
    name: 'Brazilian_Sugar',
    cover: `${IMG_PATH}sugar/1.webp`,
    images: [
      `${IMG_PATH}sugar/1.webp`,
      `${IMG_PATH}sugar/2.webp`,
      `${IMG_PATH}sugar/3.webp`,
      `${IMG_PATH}sugar/4.webp`,
      `${IMG_PATH}sugar/5.webp`,
    ],

    category: 'Grains',
    items: ['ICUMSA 45 SUGAR', 'ICUMSA 150 SUGAR', 'ICUMSA 600-1200 SUGAR'],
  },
  {
    id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7a3',
    name: 'livestock',
    cover: `${IMG_PATH}livestock/1.webp`,
    images: [
      `${IMG_PATH}livestock/1.webp`,
      `${IMG_PATH}livestock/2.webp`,
      `${IMG_PATH}livestock/3.webp`,
      `${IMG_PATH}livestock/4.webp`,
      `${IMG_PATH}livestock/5.webp`,
      `${IMG_PATH}livestock/6.webp`,
    ],

    category: 'Grains',
    items: ['LIVESTOCK', 'CHICKEN', 'SEA FOOD', 'MEAT', 'DAIRY PRODUCTS', 'TONA'],
  },
  {
    id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7a4',
    name: 'Vegetables',
    cover: `${IMG_PATH}vegetables/1.webp`,
    images: [
      `${IMG_PATH}vegetables/1.webp`,
      `${IMG_PATH}vegetables/2.webp`,
      `${IMG_PATH}vegetables/3.webp`,
      `${IMG_PATH}vegetables/4.webp`,
      `${IMG_PATH}vegetables/5.webp`,
    ],

    category: 'Grains',
    items: ['FRESH VEGETABLES AND FRUITS', 'FROZEN VEGETABLES AND FRUITS', 'CANNED VEGETABLES AND FRUITS'],
  },
  {
    id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7a5',
    name: 'Coffee',
    cover: `${IMG_PATH}coffee/1.webp`,
    images: [
      `${IMG_PATH}coffee/1.webp`,
      `${IMG_PATH}coffee/4.webp`,
      `${IMG_PATH}coffee/5.webp`,
      `${IMG_PATH}coffee/6.webp`,
      `${IMG_PATH}coffee/7.webp`,
    ],

    category: 'Grains',
    items: ['COFFEE', 'CHOCOLATE', 'CARDAMOM'],
    supportingDoc: [
      { fileName: 'RESOLUCIÓN 02 DE 2016 Café Verde', url: '/assets/doc/coffee_cer1.pdf', id: 111 },
      { fileName: 'SOUTH AMERICA EXPORT AND INVESTMENTS S.A.S.', url: '/assets/doc/coffee_cer2.pdf', id: 112 },
      { fileName: 'SOUTH AMERICA EXPORT AND INVESTMENTS S.A', url: '/assets/doc/coffee_cer3.pdf', id: 113 },
    ],
  },
  {
    id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5',
    name: 'Oil',
    cover: `${IMG_PATH}oil/1.webp`,
    images: [`${IMG_PATH}oil/1.webp`, `${IMG_PATH}oil/2.webp`, `${IMG_PATH}oil/3.webp`, `${IMG_PATH}oil/4.webp`],

    category: 'Oil',
    items: ['Sunflower Oil', 'Olive Oil', 'Soya Oil'],
  },
  {
    id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7a6',
    name: 'Mining',
    cover: `${IMG_PATH}mining/5.webp`,
    images: [
      `${IMG_PATH}mining/5.webp`,
      `${IMG_PATH}mining/2.webp`,
      `${IMG_PATH}mining/3.webp`,
      `${IMG_PATH}mining/4.webp`,
    ],

    category: 'Grains',
    items: ['GOLD', 'CUPPER', 'COAL', 'PET COKE', 'SCRAP METAL', 'GEMSTONES'],
  },
  {
    id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7a7',
    name: 'Petroleum',
    cover: `${IMG_PATH}petroleum/1.webp`,
    images: [
      `${IMG_PATH}petroleum/1.webp`,
      `${IMG_PATH}petroleum/2.webp`,
      `${IMG_PATH}petroleum/3.webp`,
      `${IMG_PATH}petroleum/4.webp`,
      `${IMG_PATH}petroleum/5.webp`,
    ],

    category: 'Grains',
    items: ['DIESEL D2 500 PPM', 'DIESEL EN590 50 PPM', 'DIESEL EN590 10 PPM', 'JET A1 FUEL', 'D6 FUEL', 'CRUDE OIL'],
  },
  {
    id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7a8',
    name: 'Finance',
    cover: `${IMG_PATH}finance/1.webp`,
    images: [
      `${IMG_PATH}finance/5.jpg`,
      `${IMG_PATH}finance/2.jpg`,
      `${IMG_PATH}finance/3.jpg`,
      `${IMG_PATH}finance/4.jpg`,
      `${IMG_PATH}finance/6.jpg`,
      `${IMG_PATH}finance/7.jpg`,
      `${IMG_PATH}finance/8.jpg`,
      `${IMG_PATH}finance/9.jpg`,
      `${IMG_PATH}finance/10.jpg`,
      `${IMG_PATH}finance/11.jpg`,
    ],
    category: 'Finance',
    items: [
      '/assets/illustrations/illustration_financeIssue.svg',
      '/assets/illustrations/illustration_cashAdvance.svg',
      '/assets/illustrations/illustration_treasury.svg',
    ],
  },
  {
    id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7a9',
    name: 'logistics',
    cover: `${IMG_PATH}logistics/1.webp`,
    images: [
      `${IMG_PATH}logistics/1.webp`,
      `${IMG_PATH}logistics/2.webp`,
      `${IMG_PATH}logistics/3.webp`,
      `${IMG_PATH}logistics/4.webp`,
      `${IMG_PATH}logistics/5.webp`,
    ],

    category: 'Grains',
    items: ['logistics', 'transportation'],
  },
  // {
  //   id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2',
  //   cover: 'https://minimal-assets-api-dev.vercel.app/assets/images/products/product_2.jpg',
  //   images: [
  //     'https://minimal-assets-api-dev.vercel.app/assets/images/products/product_1.jpg',
  //     'https://minimal-assets-api-dev.vercel.app/assets/images/products/product_2.jpg',
  //     'https://minimal-assets-api-dev.vercel.app/assets/images/products/product_3.jpg',
  //     'https://minimal-assets-api-dev.vercel.app/assets/images/products/product_4.jpg',
  //     'https://minimal-assets-api-dev.vercel.app/assets/images/products/product_5.jpg',
  //     'https://minimal-assets-api-dev.vercel.app/assets/images/products/product_6.jpg',
  //     'https://minimal-assets-api-dev.vercel.app/assets/images/products/product_7.jpg',
  //     'https://minimal-assets-api-dev.vercel.app/assets/images/products/product_8.jpg',
  //   ],
  //   name: 'Foundations Matte Flip Flop',
  //   code: '38BEE271',
  //   sku: 'WW75K5211YW/SV',
  //   tags: ['Dangal', 'The Sting', '2001: A Space Odyssey', "Singin' in the Rain"],
  //   price: 35.71,
  //   priceSale: null,
  //   totalRating: 2,
  //   totalReview: 9371,
  //   ratings: [
  //     {
  //       name: '1 Star',
  //       starCount: 2049,
  //       reviewCount: 8137,
  //     },
  //     {
  //       name: '2 Star',
  //       starCount: 4734,
  //       reviewCount: 3212,
  //     },
  //     {
  //       name: '3 Star',
  //       starCount: 4372,
  //       reviewCount: 865,
  //     },
  //     {
  //       name: '4 Star',
  //       starCount: 9682,
  //       reviewCount: 7199,
  //     },
  //     {
  //       name: '5 Star',
  //       starCount: 7141,
  //       reviewCount: 4916,
  //     },
  //   ],
  //   reviews: [
  //     {
  //       id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1',
  //       name: 'Jayvion Simon',
  //       avatarUrl: 'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_1.jpg',
  //       comment: 'Assumenda nam repudiandae rerum fugiat vel maxime.',
  //       rating: 2.5,
  //       isPurchased: true,
  //       helpful: 5234,
  //       postedAt: '2022-08-31T05:49:59.374Z',
  //     },
  //     {
  //       id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2',
  //       name: 'Lucian Obrien',
  //       avatarUrl: 'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_2.jpg',
  //       comment: 'Quis veniam aut saepe aliquid nulla.',
  //       rating: 2,
  //       isPurchased: true,
  //       helpful: 8448,
  //       postedAt: '2022-08-30T04:49:59.374Z',
  //     },
  //     {
  //       id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3',
  //       name: 'Deja Brady',
  //       avatarUrl: 'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_3.jpg',
  //       comment: 'Reprehenderit ut voluptas sapiente ratione nostrum est.',
  //       rating: 4.9,
  //       isPurchased: true,
  //       helpful: 8510,
  //       postedAt: '2022-08-29T03:49:59.374Z',
  //     },
  //     {
  //       id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b4',
  //       name: 'Harrison Stein',
  //       avatarUrl: 'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_4.jpg',
  //       comment: 'Error ut sit vel molestias velit.',
  //       rating: 2,
  //       isPurchased: false,
  //       helpful: 8273,
  //       postedAt: '2022-08-28T02:49:59.374Z',
  //     },
  //     {
  //       id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5',
  //       name: 'Reece Chung',
  //       avatarUrl: 'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_5.jpg',
  //       comment: 'Quo quia sit nihil nemo doloremque et.',
  //       rating: 4,
  //       isPurchased: false,
  //       helpful: 4269,
  //       postedAt: '2022-08-27T01:49:59.374Z',
  //     },
  //     {
  //       id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b6',
  //       name: 'Lainey Davidson',
  //       avatarUrl: 'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_6.jpg',
  //       comment: 'Autem doloribus harum vero laborum.',
  //       rating: 5,
  //       isPurchased: true,
  //       helpful: 7940,
  //       postedAt: '2022-08-26T00:49:59.374Z',
  //     },
  //     {
  //       id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b7',
  //       name: 'Cristopher Cardenas',
  //       avatarUrl: 'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_7.jpg',
  //       comment: 'Tempora officiis consequuntur architecto nostrum autem nam adipisci.',
  //       rating: 4.9,
  //       isPurchased: false,
  //       helpful: 5221,
  //       postedAt: '2022-08-24T23:49:59.374Z',
  //     },
  //     {
  //       id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b8',
  //       name: 'Melanie Noble',
  //       avatarUrl: 'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_8.jpg',
  //       comment: 'Voluptas sunt magni adipisci praesentium saepe.',
  //       rating: 5,
  //       isPurchased: false,
  //       helpful: 7177,
  //       postedAt: '2022-08-23T22:49:59.374Z',
  //     },
  //   ],
  //   status: '',
  //   inventoryType: 'in_stock',
  //   sizes: ['6', '7', '8', '8.5', '9', '9.5', '10', '10.5', '11', '11.5', '12', '13'],
  //   available: 2,
  //   description:
  //     '\n<p><strong><small> SPECIFICATION</small></strong></p>\n<p>Leather panels. Laces. Rounded toe. Rubber sole.\n<br /><br />\n<p><strong><small> MATERIAL AND WASHING INSTRUCTIONS</small></strong></p>\n<p>Shoeupper: 54% bovine leather,46% polyurethane. Lining: 65% polyester,35% cotton. Insole: 100% polyurethane. Sole: 100% thermoplastic. Fixing sole: 100% glued.</p>\n',
  //   sold: 487,
  //   createdAt: '2022-08-30T04:49:59.374Z',
  //   category: 'Accessories',
  //   gender: 'Kids',
  //   colors: ['#000000', '#FFFFFF'],
  // },
];

const initialState = {
  isLoading: false,
  error: null,
  products: [...productsList],
  product: null,
  sortBy: null,
  filters: {
    gender: [],
    category: 'All',
    colors: [],
    priceRange: [0, 200],
    rating: '',
  },
  checkout: {
    activeStep: 0,
    cart: [],
    subtotal: 0,
    total: 0,
    discount: 0,
    shipping: 0,
    billing: null,
  },
};

const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getProductsSuccess(state, action) {
      state.isLoading = false;
      state.products = action.payload;
    },

    // GET PRODUCT
    getProductSuccess(state, action) {
      state.isLoading = false;
      state.product = action.payload;
    },

    //  SORT & FILTER PRODUCTS
    sortByProducts(state, action) {
      state.sortBy = action.payload;
    },

    filterProducts(state, action) {
      state.filters.gender = action.payload.gender;
      state.filters.category = action.payload.category;
      state.filters.colors = action.payload.colors;
      state.filters.priceRange = action.payload.priceRange;
      state.filters.rating = action.payload.rating;
    },

    // CHECKOUT
    getCart(state, action) {
      const cart = action.payload;

      const subtotal = sum(cart.map((cartItem) => cartItem.price * cartItem.quantity));
      const discount = cart.length === 0 ? 0 : state.checkout.discount;
      const shipping = cart.length === 0 ? 0 : state.checkout.shipping;
      const billing = cart.length === 0 ? null : state.checkout.billing;

      state.checkout.cart = cart;
      state.checkout.discount = discount;
      state.checkout.shipping = shipping;
      state.checkout.billing = billing;
      state.checkout.subtotal = subtotal;
      state.checkout.total = subtotal - discount;
    },

    addCart(state, action) {
      const product = action.payload;
      const isEmptyCart = state.checkout.cart.length === 0;

      if (isEmptyCart) {
        state.checkout.cart = [...state.checkout.cart, product];
      } else {
        state.checkout.cart = state.checkout.cart.map((_product) => {
          const isExisted = _product.id === product.id;
          if (isExisted) {
            return {
              ..._product,
              quantity: _product.quantity + 1,
            };
          }
          return _product;
        });
      }
      state.checkout.cart = uniqBy([...state.checkout.cart, product], 'id');
    },

    deleteCart(state, action) {
      const updateCart = state.checkout.cart.filter((item) => item.id !== action.payload);

      state.checkout.cart = updateCart;
    },

    resetCart(state) {
      state.checkout.activeStep = 0;
      state.checkout.cart = [];
      state.checkout.total = 0;
      state.checkout.subtotal = 0;
      state.checkout.discount = 0;
      state.checkout.shipping = 0;
      state.checkout.billing = null;
    },

    onBackStep(state) {
      state.checkout.activeStep -= 1;
    },

    onNextStep(state) {
      state.checkout.activeStep += 1;
    },

    onGotoStep(state, action) {
      const goToStep = action.payload;
      state.checkout.activeStep = goToStep;
    },

    increaseQuantity(state, action) {
      const productId = action.payload;
      const updateCart = state.checkout.cart.map((product) => {
        if (product.id === productId) {
          return {
            ...product,
            quantity: product.quantity + 1,
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    decreaseQuantity(state, action) {
      const productId = action.payload;
      const updateCart = state.checkout.cart.map((product) => {
        if (product.id === productId) {
          return {
            ...product,
            quantity: product.quantity - 1,
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    createBilling(state, action) {
      state.checkout.billing = action.payload;
    },

    applyDiscount(state, action) {
      const discount = action.payload;
      state.checkout.discount = discount;
      state.checkout.total = state.checkout.subtotal - discount;
    },

    applyShipping(state, action) {
      const shipping = action.payload;
      state.checkout.shipping = shipping;
      state.checkout.total = state.checkout.subtotal - state.checkout.discount + shipping;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getCart,
  addCart,
  resetCart,
  onGotoStep,
  onBackStep,
  onNextStep,
  deleteCart,
  createBilling,
  applyShipping,
  applyDiscount,
  increaseQuantity,
  decreaseQuantity,
  sortByProducts,
  filterProducts,
} = slice.actions;

// ----------------------------------------------------------------------

// export function getProducts() {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.get('/api/products');
//       // dispatch(slice.actions.getProductsSuccess(response.data.products));
//       dispatch(slice.actions.getProductsSuccess(response.data.products));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

// ----------------------------------------------------------------------

export function getProduct(name) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // const response = await axios.get('/api/products/product', {
      //   params: { name },
      // });
      // dispatch(slice.actions.getProductSuccess(response.data.product));
      dispatch(
        slice.actions.getProductSuccess(
          productsList.filter((product) => paramCase(product.name).toLocaleLowerCase() === name)[0]
        )
      );
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
